import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons"

const ProjectsGridItem = ({ item }) => {
  return (
    <Link className="relative block overflow-hidden group" link={item.link}>
      <div className="aspect-[2/3]">
        <Image
          className="object-cover w-full h-full"
          aspectRatio="2by3"
          image={item.image}
        />
      </div>
      <div className="absolute inset-0 transition-opacity duration-500 ease-in-out bg-black opacity-0 dur bg-opacity-30 group-hover:opacity-100"></div>
      <div className="absolute inset-x-0 transition-transform duration-500 ease-in-out transform translate-y-full group-hover:translate-y-0 bottom-0 pt-2.5 bg-white">
        <div className="flex w-full btn btn-primary">
          {item.link_text}
          <FontAwesomeIcon
            icon={faArrowRight}
            size="1x"
            className="inline-block mt-1 ml-auto -mr-1.5"
          />
        </div>
      </div>
    </Link>
  )
}

const ProjectsGridBlock = ({ block }) => {
  return (
    <BlockWrapper block={block} showHeadline="false" blockPadding="py-20">
      <div className="flex flex-row flex-wrap -mx-half-grid">
        <div className="w-full lg:w-1/2 -my-2.5">
          <div className="px-half-grid pt-2.5 pb-2.5">
            <HeadlineEnhanced kicker={block.kicker} headline={block.headline} />
            <Richtext className="mt-8 lg:mr-2.5" text={block.text} />
          </div>
          <div className="flex flex-row">
            <div className="w-1/2">
              <div className="hidden pb-3 lg:block px-half-grid">
                <div className="block aspect-[2/1.5]"></div>
              </div>
              {block.column_1?.length > 0 &&
                block.column_1.map((item, index) => (
                  <div className="py-2.5 px-half-grid" key={"1-" + index}>
                    <ProjectsGridItem item={item} />
                  </div>
                ))}
            </div>
            <div className="w-1/2">
              {block.column_2?.length > 0 &&
                block.column_2.map((item, index) => (
                  <div className="py-2.5 px-half-grid" key={"1-" + index}>
                    <ProjectsGridItem item={item} />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="flex flex-row">
            <div className="w-1/2">
              {block.column_3?.length > 0 &&
                block.column_3.map((item, index) => (
                  <div className="py-2.5 px-half-grid" key={"1-" + index}>
                    <ProjectsGridItem item={item} />
                  </div>
                ))}
            </div>
            <div className="w-1/2">
              <div className="pb-1 px-half-grid">
                <div className="hidden lg:block aspect-[2/1.5]"></div>
              </div>
              {block.column_4?.length > 0 &&
                block.column_4.map((item, index) => (
                  <div className="py-2.5 px-half-grid" key={"1-" + index}>
                    <ProjectsGridItem item={item} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </BlockWrapper>
  )
}

export default ProjectsGridBlock
